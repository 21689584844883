<template>
    <div class="main">
        <Layout>
            <Content>
                <div class="unauthorized">
                    <p>暂无权限，请联系管理员</p>
                </div>
            </Content>
            <Footer></Footer>
        </Layout>
    </div>
</template>
<script>
    import Header from '../components/Header/Header.vue'

    export default {
        components: {
            Header: Header
        },
        data() {
            return {
            }
        },
        methods: {
        },
        mounted() {
        }
    }
</script>
<style lang="less" scoped>
    .ivu-layout-content {
        height: 100%;

        .unauthorized {
            display: block;
            width: 400px;
            height: 100px;
            margin: 15% auto 0;
            padding: 240px 0 60px;
            color: #C5C8CE;
            text-align: center;
            background-image: url(../assets/images/unauthorized.png);
            background-position: center center;
            background-repeat: no-repeat;
        }
    }
</style>
